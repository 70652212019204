import React from "react"
import classNames from "classnames"
import componentStyles from "./PreviewAccordionSection.module.scss"
import { previewAccordionData } from "../../models/previewAccordionData";

interface PreviewAccordionSection {
    open: boolean;
    onClick: () => void;
    data: previewAccordionData;
}

const PreviewAccordionSection: React.FC<PreviewAccordionSection>  = ({open, onClick, data}) => {

    return (
        <div
            className={classNames(
            componentStyles.container,
            { [componentStyles.open]: open})}
            onClick={onClick}
        >
            <img src={data.image} alt="preview image" className={componentStyles.prewiewImg} />
            <div className={componentStyles.middle}>
                <div className={componentStyles.name}>
                    {data.name}
                </div>
                <div className={componentStyles.post}>
                    {data.post}
                </div>
                <div className={componentStyles.description}>
                    {data.description}
                </div>
            </div>
            <div
                className={componentStyles.accordionArrow}
            >
                <svg
                    className={componentStyles.accordionArrowSvg}
                    width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.69845 7.00005L0.231934 1.64023L1.76838 0.359863L7.30186 7.00005L1.76838 13.6402L0.231934 12.3599L4.69845 7.00005Z" fill="#050D71"/>
                </svg>
            </div>
        </div>
    )
}

export default PreviewAccordionSection