import React, { MouseEventHandler, useEffect, useState } from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout/Layout"
import componentStyles from "./about.module.scss"
import SEO from "../components/seo"
import { Link } from "../components/Link"
import classNames from "classnames"
import AdvantagesBlock from "../components/AdvantagesBlock/AdvantagesBlock"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Timeline from "../components/Timeline/Timeline"
import PreviewAccordion from "../components/PreviewAccordion/PreviewAccordion"

const About: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()

  //for adaptive images size
  const breakpoints = useBreakpoint();

  const [isOpen, setIsOpen] = useState(false)

  const hiddenTextClass = !isOpen ? componentStyles.hidden : '';
  const textToggleBtn = isOpen
    ? intl.formatMessage({ id: "about.story.frame.less" })
    : intl.formatMessage({ id: "about.story.frame.more" });

  const scrollToAnchor: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();

    const hash = event.currentTarget.getAttribute("href");
    const target = document.querySelector(hash);

    if (!target) {
      return;
    }

    target.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });

    const url = new URL(window.location.href);
    url.hash = hash;

    window.history.pushState(null, null, url.toString());
  }

  const ourTeamRef = React.useRef<HTMLInputElement>(null);
  const ourStoryRef = React.useRef<HTMLInputElement>(null);
  const leadershipRef = React.useRef<HTMLInputElement>(null);

  const [numberOfActiveAnchor, setNumberOfActiveAnchor] = useState(1)

  const onHeaderScroll = () => {
    const scrolled = window.scrollY
    const ourStoryRefOffsetTop = ourStoryRef.current.offsetTop - 100;
    const leadershipRefOffsetTop = leadershipRef.current.offsetTop - 100;
    setNumberOfActiveAnchor(1)

    if (scrolled > ourStoryRefOffsetTop) {
      setNumberOfActiveAnchor(2)
    }
    if (scrolled > leadershipRefOffsetTop) {
      setNumberOfActiveAnchor(3)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onHeaderScroll)
    return () => {
      window.removeEventListener("scroll", onHeaderScroll)
    }
  }, [])

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({ id: "about.seo.description" })}
        title={intl.formatMessage({ id: "about.seo.title" })}
        pathname={location.pathname}
      />
      <div className={componentStyles.containerAnchors + ' ' + componentStyles.sticky}>
        <div className="container">
          <a
            className={classNames(
              componentStyles.anchorLink,
              {
                [componentStyles.active]: numberOfActiveAnchor === 1,
              }
            )}
            onClick={scrollToAnchor}
            href="#ourTeam"
          >
            {intl.formatMessage({ id: "about.team.title" })}
          </a>
          <a
            className={classNames(
              componentStyles.anchorLink,
              {
                [componentStyles.active]: numberOfActiveAnchor === 2,
              }
            )}
            onClick={scrollToAnchor}
            href="#storyBlock"
          >
            {intl.formatMessage({ id: "about.story.title" })}
          </a>
          <a
            className={classNames(
              componentStyles.anchorLink,
              {
                [componentStyles.active]: numberOfActiveAnchor === 3,
              }
            )}
            onClick={scrollToAnchor}
            href="#leadership"
          >
            {intl.formatMessage({ id: "about.leadership.title" })}
          </a>
        </div>
      </div>
      <section className="container">
        <h1 className={componentStyles.mainTitle}>
          {intl.formatMessage({ id: "about.banner.title" })}
        </h1>
        <div className={componentStyles.teamWithImage}>
          <span>{intl.formatMessage({ id: "index.company.image-title" })}</span>
        </div>
      </section>
      <section className={componentStyles.aboutBlock + ' ' + "container"} id="ourTeam" ref={ourTeamRef}>
        <h2 className={componentStyles.secondaryTitle + ' ' + componentStyles.mobileOurTeamText}>
          {intl.formatMessage({ id: "about.team.title" })}
        </h2>
        <div className="grid-2">
          <div>
            <h2 className={componentStyles.secondaryTitle + ' ' + componentStyles.ourTeamText}>
              {intl.formatMessage({ id: "about.team.title" })}
            </h2>
            <div className={componentStyles.text}>
              {intl.formatMessage({ id: "about.team.text" })}
            </div>
          </div>
          <AdvantagesBlock />
        </div>
        <div className={componentStyles.companyAwards}>
        <Link
            className={componentStyles.companyAwardsItem}
            to="//clutch.co/press-releases/uae%E2%80%99s-2021-leading-b2b-companies-honored-celebrated"
          >
            <picture>
              <source
                type="image/webp"
                srcSet='/images/clutch.webp'
              />
              <source
                type="image/png"
                srcSet='/images/clutch.png'
              />
              <img
                className={componentStyles.companyAwardsBadge}
                src="/images/clutch.png"
                alt="Clutch winner badge"
                width={!breakpoints.mediaBefore800 ? '90' : '65'}
                height={!breakpoints.mediaBefore800 ? '98' : '68'}
              />
            </picture>
            <span className={componentStyles.companyAwardsDescription}>
              {intl.formatMessage({ id: "index.company.clutch-fusten-growth" })}
            </span>
          </Link>
          <Link
            className={componentStyles.companyAwardsItem}
            to="//clutch.co/press-releases/uae%E2%80%99s-2021-leading-b2b-companies-honored-celebrated"
          >
            <picture>
              <source
                type="image/webp"
                srcSet='/images/clutch-winners.webp'
              />
              <source
                type="image/png"
                srcSet='/images/clutch-winners.png'
              />
              <img
                className={componentStyles.companyAwardsBadge}
                src="/images/clutch-winners.png"
                alt="Clutch winner badge"
                width={!breakpoints.mediaBefore800 ? '90' : '65'}
                height={!breakpoints.mediaBefore800 ? '98' : '68'}
              />
            </picture>
            <span className={componentStyles.companyAwardsDescription}>
              {intl.formatMessage({ id: "index.company.clutch-winners" })}
            </span>
          </Link>
          <Link
            to="//www.goodfirms.co/company/inventale"
            className={componentStyles.companyAwardsItem}
          >
            <img
              className={componentStyles.companyAwardsBadge}
              src="/images/top-software.svg"
              alt="Goodfirms Top Software Development Company badge"
              width={!breakpoints.mediaBefore800 ? '112' : '77'}
              height={!breakpoints.mediaBefore800 ? '98' : '67'}
            />
            <span className={componentStyles.companyAwardsDescription}>
              {intl.formatMessage({ id: "index.company.bottom-block.first" })}
            </span>
          </Link>
          <Link
            to="//www.goodfirms.co/company/inventale"
            className={componentStyles.companyAwardsItem}
          >
            <img
              className={componentStyles.companyAwardsBadge}
              src="/images/big-data-analytics.svg"
              alt="Goodfirms Top Big Data Analytics Company badge"
              width={!breakpoints.mediaBefore800 ? '112' : '77'}
              height={!breakpoints.mediaBefore800 ? '98' : '67'}
            />
            <span className={componentStyles.companyAwardsDescription}>
              {intl.formatMessage({
                id: "index.company.bottom-block.second",
              })}
            </span>
          </Link>
          <Link
            to="//www.goodfirms.co/company/inventale"
            className={componentStyles.companyAwardsItem}
          >
            <img
              className={componentStyles.companyAwardsBadge}
              src="/images/artificial-intelligence.svg"
              alt="Goodfirms Top Artificial Intelligence Company badge"
              width={!breakpoints.mediaBefore800 ? '112' : '77'}
              height={!breakpoints.mediaBefore800 ? '98' : '67'}
            />
            <span className={componentStyles.companyAwardsDescription}>
              {intl.formatMessage({ id: "index.company.bottom-block.third" })}
            </span>
          </Link>
        </div>
      </section>
      <section className={componentStyles.storyBlock} id="storyBlock" ref={ourStoryRef}>
        <div className={componentStyles.backgroundBlock}>
          <div className="container">
            <div>
              <h2 className={componentStyles.secondaryTitle}>
                {intl.formatMessage({ id: "about.story.title" })}
              </h2>
              <p className={componentStyles.text}>
                {intl.formatMessage({ id: "about.story.paragraph-1" })}
              </p>
            </div>
            <div className={componentStyles.frame + ' ' + componentStyles.text}>
              <p>
                {intl.formatMessage({ id: "about.story.frame.paragraph-1" })}
              </p>
              <div>
                {intl.formatMessage({ id: "about.story.frame.paragraph-2" })}
              </div>
              <div className={hiddenTextClass + ' ' + componentStyles.additionalBlock}>
                <h2>{intl.formatMessage({ id: "about.story.frame.highlighted" })}</h2>
                <p>
                  {intl.formatMessage({ id: "about.story.frame.paragraph-3" })}
                </p>
                <div className={componentStyles.dataBlock}>
                  <img
                    src="/images/screenshots.png"
                    alt="screenshots"
                    width={!breakpoints.mediaBefore800 ? '366' : '265'}
                    height={!breakpoints.mediaBefore800 ? '276' : '154'}
                  />
                  <div className={componentStyles.rightBlock}>
                    <div className={componentStyles.smallBlock}>
                      <div className={componentStyles.digit}>
                        {intl.formatMessage({ id: "about.story.frame.data.paragraph-1.title" })}
                      </div>
                      <div className={componentStyles.text}>
                        {intl.formatMessage({ id: "about.story.frame.data.paragraph-1.text" })}
                      </div>
                    </div>
                    <div className={componentStyles.smallBlock}>
                      <div className={componentStyles.digit}>
                        {intl.formatMessage({ id: "about.story.frame.data.paragraph-2.title" })}
                      </div>
                      <div className={componentStyles.text}>
                        {intl.formatMessage({ id: "about.story.frame.data.paragraph-2.text" })}
                      </div>
                    </div>
                    <div className={componentStyles.smallBlock}>
                      <div className={componentStyles.digit}>
                        {intl.formatMessage({ id: "about.story.frame.data.paragraph-3.title" })}
                      </div>
                      <div className={componentStyles.text}>
                        {intl.formatMessage({ id: "about.story.frame.data.paragraph-3.text" })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button className={componentStyles.toggle} onClick={() => setIsOpen(!isOpen)}>{textToggleBtn}</button>
            </div>
            <div >
              <p className={componentStyles.text}>
                {intl.formatMessage({ id: "about.story.paragraph-2" })}
              </p>
              <p className={componentStyles.text}>
                {intl.formatMessage({ id: "about.story.paragraph-3" })}
              </p>
            </div>
            <div className={componentStyles.wrapTimeline}>
              <Timeline />
            </div>
          </div>
        </div>
      </section>
      <section className="container" id="leadership" ref={leadershipRef}>
        <div className={componentStyles.leadership}>
          <h2 className={componentStyles.secondaryTitle}>
            {intl.formatMessage({ id: "about.leadership.title" })}
          </h2>
          <PreviewAccordion />
        </div>
      </section>
    </Layout>
  )
}
export default About
