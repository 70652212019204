import { useIntl } from "gatsby-plugin-intl"
import React, { useMemo, useState } from "react"
import componentStyles from "./Timeline.module.scss"
import TimelinePoint from "../TimeLinePoint/TimeLinePoint";
import { TimePointData } from "../../models/TimePointData";

const Timeline = () => {
    const intl = useIntl()

    const shiftLeft = 130;
    const timePointsData: TimePointData[] = useMemo(() => Array.apply(null, Array(6)).map((val, idx) => ({
        text: intl.formatMessage({ id: "about.story.timeline.point-" + (idx + 1) + ".text" }),
        year: intl.formatMessage({ id: "about.story.timeline.point-" + (idx + 1) + ".title" }),
    })), []);

    const [selectedTimePointIndex, setSelectedTimePointIndex] = useState<null | number>(null)

    return (
        <div className={componentStyles.wrapper}>
            <div className={componentStyles.title}>
                {intl.formatMessage({ id: "about.story.timeline.title" })}
            </div>
            <div className={componentStyles.timeline}>
                <div className={componentStyles.whiteLine}>
                    {timePointsData.map((element, index) => (
                        <TimelinePoint
                            key={index}
                            open={index === selectedTimePointIndex}
                            shiftLeft={shiftLeft+(index * 170)}
                            onClick= {()=> setSelectedTimePointIndex(index)}
                            data={element}
                            onClose={() => setSelectedTimePointIndex(null)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
  }

  export default Timeline