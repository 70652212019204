import { useIntl } from "gatsby-plugin-intl"
import React, { useCallback, useMemo, useRef } from "react"
import classNames from "classnames"
import componentStyles from "./TimeLinePoint.module.scss"
import useClickAway from "../../hooks/useClickAway";
import { TimePointData } from "../../models/TimePointData";
import CloseIcon from "../../assets/images/icons/close-icon.svg"

interface TimelinePoint {
    open: boolean;
    onClick: () => void;
    shiftLeft: number;
    data: TimePointData;
    onClose: () => void;
}

const TimelinePoint: React.FC<TimelinePoint>  = ({open, onClick, shiftLeft, data, onClose}) => {

    const handleClose = useCallback(() => {
        if (open) {
            onClose();
        }
    }, [onClose])

    const popupEl = useRef(null)
    useClickAway(popupEl, handleClose)

    const getRandomDelay = useMemo(() => Math.random()*1500, [])

    return (
        <div className={classNames(
                componentStyles.circleContainer,
                { [componentStyles.open]: open}
            )}
            style={{left: shiftLeft + "px"}}>
            <div className={componentStyles.circleActiveArea} onClick={onClick}>
                <div className={componentStyles.circleAround} style={{animationDelay: getRandomDelay  + 'ms' }}>
                </div>
                <div className={componentStyles.circle}></div>
            </div>
            <div className={componentStyles.year}>
                {data.year}
            </div>
            <div ref={popupEl} className={componentStyles.popup}>
                <div className={componentStyles.title}>
                    {data.year}
                </div>
                <img
                    className={componentStyles.closeIcon}
                    src={CloseIcon}
                    alt="Close icon"
                    width="18"
                    height="18"
                    onClick={onClose}
                />
                {data.text}
                <div className={componentStyles.triangle}></div>
            </div>
        </div>
    )
  }

  export default TimelinePoint