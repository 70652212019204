import { useIntl } from "gatsby-plugin-intl"
import React, { useMemo, useRef, useState } from "react"
import classNames from "classnames"
import componentStyles from "./PreviewAccordion.module.scss"
import { previewAccordionData } from "../../models/previewAccordionData";
import PreviewAccordionSection from "../PreviewAccordionSection/PreviewAccordionSection";

const PreviewAccordion = () => {
    const intl = useIntl()

    const previewAccordionData: previewAccordionData[] = useMemo(() => [
        {
            image: "/images/leadership/Igor_Minakov.jpg",
            name: intl.formatMessage({ id: "about.leadership.accordion.card-1.name" }),
            post: intl.formatMessage({ id: "about.leadership.accordion.card-1.post" }),
            description: intl.formatMessage({ id: "about.leadership.accordion.card-1.description" }),
        },
        {
            image: "/images/leadership/Irina Prilutskaya.jpg",
            name: intl.formatMessage({ id: "about.leadership.accordion.card-2.name" }),
            post: intl.formatMessage({ id: "about.leadership.accordion.card-2.post" }),
            description: intl.formatMessage({ id: "about.leadership.accordion.card-2.description" }),
        },
        {
            image: "/images/leadership/Maria Borisova.jpg",
            name: intl.formatMessage({ id: "about.leadership.accordion.card-3.name" }),
            post: intl.formatMessage({ id: "about.leadership.accordion.card-3.post" }),
            description: intl.formatMessage({ id: "about.leadership.accordion.card-3.description" }),
        },
        {
            image: "/images/leadership/Dmitry Yolkin.jpg",
            name: intl.formatMessage({ id: "about.leadership.accordion.card-4.name" }),
            post: intl.formatMessage({ id: "about.leadership.accordion.card-4.post" }),
            description: intl.formatMessage({ id: "about.leadership.accordion.card-4.description" }),
        },
        {
            image: "/images/leadership/Othmane Kada.jpg",
            name: intl.formatMessage({ id: "about.leadership.accordion.card-5.name" }),
            post: intl.formatMessage({ id: "about.leadership.accordion.card-5.post" }),
            description: intl.formatMessage({ id: "about.leadership.accordion.card-5.description" }),
        },
        {
            image: "/images/leadership/Azat Abdulvaliev.jpg",
            name: intl.formatMessage({ id: "about.leadership.accordion.card-6.name" }),
            post: intl.formatMessage({ id: "about.leadership.accordion.card-6.post" }),
            description: intl.formatMessage({ id: "about.leadership.accordion.card-6.description" }),
        }
    ], []);

    const [selectedSectionIndex, setSelectedSectionIndex] = useState<null | number>(0)

    return (
        <div className={componentStyles.wrapper}>
            {previewAccordionData.map((element, index) => (
                <PreviewAccordionSection
                    key={index}
                    open={index === selectedSectionIndex}
                    onClick= {()=> setSelectedSectionIndex(index)}
                    data={element}
                />
            ))}
        </div>
    )
  }

  export default PreviewAccordion